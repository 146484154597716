var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customer-table-container px-0 mx-0"},[_c('b-table',_vm._g({staticClass:"table-list",attrs:{"responsive":"","fixed":"","no-local-sorting":"","items":_vm.customers,"fields":_vm.fields,"sort-by":_vm.queryParams.sortBy,"sort-desc":_vm.queryParams.sortOrder === 'desc'},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({width: _vm.colWidths[field.key]})})})}},{key:"head(updated)",fn:function(data){return [_c('div',{staticClass:"light-border-right"},[_c('div',{staticClass:"d-flex column-explanation"},[_c('div',[_vm._v(" "+_vm._s(data.label)+" "),_c('div',{staticClass:"mt-1 column-explanation-text"},[_vm._v(_vm._s(_vm.t('dateHint')))])])])])]}},{key:"cell(cartDuration)",fn:function(ref){
var item = ref.item;
return [_c('b-form-input',{staticClass:"text-center",attrs:{"type":"number","min":"1","max":"30"},model:{value:(item.cartDuration),callback:function ($$v) {_vm.$set(item, "cartDuration", _vm._n($$v))},expression:"item.cartDuration"}})]}},{key:"cell(reservationDuration)",fn:function(ref){
var item = ref.item;
return [_c('b-form-input',{staticClass:"text-center",attrs:{"type":"number","min":"1","max":"30"},model:{value:(item.reservationDuration),callback:function ($$v) {_vm.$set(item, "reservationDuration", _vm._n($$v))},expression:"item.reservationDuration"}})]}},{key:"cell(maxLenses)",fn:function(ref){
var item = ref.item;
return [_c('b-form-input',{staticClass:"text-center",attrs:{"type":"number","min":"1","max":"4"},model:{value:(item.maxLenses),callback:function ($$v) {_vm.$set(item, "maxLenses", _vm._n($$v))},expression:"item.maxLenses"}})]}},{key:"cell(hidePatientNames)",fn:function(ref){
var item = ref.item;
return [_c('b-row',{attrs:{"align-h":"center"}},[_c('b-form-checkbox',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],model:{value:(item.hidePatientNames),callback:function ($$v) {_vm.$set(item, "hidePatientNames", $$v)},expression:"item.hidePatientNames"}})],1)]}},{key:"cell(action)",fn:function(ref){
var item = ref.item;
return [_c('b-row',{attrs:{"align-h":"center"}},[_c('b-button',{staticClass:"ml-1",attrs:{"type":"button","variant":"primary","disabled":_vm.isRowDefault(item)},on:{"click":function($event){return _vm.updateCustomer(item)}}},[_vm._v(_vm._s(_vm.t('save')))])],1)]}}])},_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }